import React from 'react';
import { Alert, Button, Card, Collapse, Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { ChevronExpand, ChevronContract } from 'react-bootstrap-icons';
import xmlFormat from 'xml-formatter';

import Loading from '../common/Loading.js';
import { postWithToken } from '../common/SecurityUtils.js';
import { classes } from '../common/Utils.js';

export default function EventDetailEditor({eventID, eventData}) {
    let markup = eventData.markup;
    try {
        markup = xmlFormat(markup);
    } catch (e) {
    }
    const [eventMarkup, setEventMarkup] = React.useState(markup);
    const [showEditor, setShowEditor] = React.useState(false);
    const [isLoaded, setIsLoaded] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const handleEditEvent = React.useCallback(() => {
        setIsLoaded(false);

        let markup = null;
        try {
            markup = xmlFormat.minify(eventMarkup, {
                filter: (node) => node.type !== 'Comment',
                collapseContent: true
            });
        } catch (error) {
            setErrorMessage(error.message)
            setIsLoaded(true);
            return;
        }
        
        const data = {
            'event': eventID,
            'markup': markup,
        };

        postWithToken('/event/edit_event/', data)
            .then(res => res.json())
            .then(
                (res) => {
                    setErrorMessage(null)
                    setIsLoaded(true);
                },
                (error) => {
                    setErrorMessage(error);
                    setIsLoaded(true);
                },
            );        
    }, [eventMarkup]);
    
    if (!isLoaded) {
        return <Loading />;
    }

    return (
        <Card className="mb-2">
            <Card.Header>
                Event Detail
                <a
                    className="eventTicketInventoryIndicator"
                    href="#"
                    onClick={() => setShowEditor(!showEditor)}>
                    {showEditor ? <ChevronContract size={20} /> : <ChevronExpand size={20} />}
                </a>
            </Card.Header>
            <Collapse in={showEditor}>
                <Card.Body>
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Event Markup</Form.Label>
                            <Form.Control
                                as="textarea"
                                onChange={e => setEventMarkup(e.target.value)}
                                rows={15}>
                                {eventMarkup}
                            </Form.Control>
                        </Form.Group>
                        <Button
                            onClick={handleEditEvent}>
                            Save
                        </Button>
                    </Form>
                </Card.Body>
            </Collapse>
        </Card>
    );
}
