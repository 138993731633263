import React from 'react';
import {
    Alert,
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    ListGroup,
    Modal,
    OverlayTrigger,
    Row,
    Tooltip
} from 'react-bootstrap';
import { Fire } from 'react-bootstrap-icons';
import {useSearchParams} from 'react-router-dom';

import Loading from '../common/Loading.js';
import PayPalPurchaseButton from '../common/PayPalPurchaseButton.js';
import { postWithToken, getWithData } from '../common/SecurityUtils.js';

import EventTicketLinkQR from './EventTicketLinkQR.js';

import { formatPrice } from '../common/Utils.js';


function renderTicketLink(link) {
    return (
        <Container style={{textAlign:'left'}}>
            <Row>
                <Col sm={4}>
                    <EventTicketLinkQR link={link} />
                </Col>
                <Col className="ticketLinkCell" sm={8}>
		    <b>Ticket Link</b><br />
		    <a href={link} target="_blank">
			{link}
		    </a>
		</Col>
            </Row>
        </Container>
    );
}

function ConfirmationModal({ticketLinks, ...props}) {
    return (
	<Modal
	    {...props}
	    size="lg"
	    aria-labelledby="confirmation-modal"
	    centered
	>
	    <Modal.Header closeButton>
		<Modal.Title id="confirmation-modal">
		    Ticket Link(s)
		</Modal.Title>
	    </Modal.Header>
	    <Modal.Body>
		<p>
		    You may take a screenshot of the ticket QR code below and use them as your digital ticket as well.
		</p>
		
                <ListGroup>
                    {ticketLinks.map(tl => <ListGroup.Item key={'ticket_id_'+tl.id}>{renderTicketLink(tl)}</ListGroup.Item>)}
                </ListGroup>
	    </Modal.Body>
	    <Modal.Footer>
		<Button onClick={props.onHide}>Close</Button>
	    </Modal.Footer>
	</Modal>
    );
}

const NO_ADD_ON_EVENT = '__NONE__';

export default function EventTicketReservationForm({eventData, eventID, canReserve}) {
    let [searchParams] = useSearchParams();
    let prefillCoupon = searchParams.get('c');

    const noSKU = !eventData.ticket_sku;
    const unitPrice = noSKU ? null : eventData.ticket_sku.price;

    const [validated, setValidated] = React.useState(false);

    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [understood, setUnderstood] = React.useState(!noSKU);
    const [numTickets, setNumTickets] = React.useState(1);
    const [coupon, setCoupon] = React.useState(prefillCoupon ?? '');
    const [usedCoupon, setUsedCoupon] = React.useState(prefillCoupon ?? '');
    const [addOnEvent, setAddOnEvent] = React.useState(null);
    
    const [totalPrice, setTotalPrice] = React.useState(unitPrice);
    const [subtotal, setSubtotal] = React.useState(unitPrice);
    const [fee, setFee] = React.useState(0.00);
    const [discount, setDiscount] = React.useState(0.00);
    const [couponAccepted, setCouponAccepted] = React.useState(null);
    
    const [ticketLinks, setTicketLinks] = React.useState([]);
    const [message, setMessage] = React.useState(null);
    const [showConf, setShowConf] = React.useState(false);
    
    const [isLoaded, setIsLoaded] = React.useState(true);

    const [isLoadingPrice, setIsLoadingPrice] = React.useState(true);

    React.useEffect(() => {
        if (noSKU) {
            if (usedCoupon) {
                setCouponAccepted(true);
            }
            return;
        }
        setIsLoadingPrice(true);

        let combo_event_id = addOnEvent == NO_ADD_ON_EVENT ? null : addOnEvent;
        const data = {
            event_id: eventID,
            num_tickets: numTickets,
            promo_code: usedCoupon,
            combo_event_id: combo_event_id,
        };

        getWithData('/event/compute_price/', data)
            .then(res => res.json())
            .then(
                (res) => {
                    setTotalPrice(res['total']);
                    setFee(res['fee']);
                    setDiscount(res['discount']);
                    setSubtotal(res['subtotal']);

                    if (usedCoupon !== null && usedCoupon !== '') {
                        // Only perform promo code related updates if user explicitly
                        // applies coupon code (e.g. promoCode is not null)
                        let accepted =
                            'coupon_applied' in res && res['coupon_applied'] === true;
                        setCouponAccepted(accepted);
                    }
                    
                    setIsLoadingPrice(true);
                },
                (error) => {
                    setIsLoadingPrice(true);
                },
            );        
    }, [numTickets, usedCoupon, addOnEvent]);
    
    const handleSubmit = React.useCallback((e) => {
        const form = e.currentTarget;
        let isValid = form.checkValidity();
        if (isValid === false) {
            e.preventDefault();
            e.stopPropagation();
        }

        setValidated(true);

        if (!isValid) {
            return;
        }

        setIsLoaded(false);
        const data = {
            'event': eventID,
            'name': name,
            'email': email,
            'num': numTickets,
            'coupon': usedCoupon ? usedCoupon : null,
        };

        postWithToken('/event/reserve_ticket/', data)
            .then(res => res.json())
            .then(
                (res) => {
                    setMessage(res.msg);
                    setTicketLinks(res.tickets);
                    setIsLoaded(true);
                },
                (error) => {
                    setMessage(error.msg);
                    setIsLoaded(true);
                },
            );        
    }, [name, email, numTickets, usedCoupon]);

    const onPaymentSuccess = React.useCallback((res) => {
        setMessage(res.msg);
        setTicketLinks(res.tickets);
    });
    
    const onPaymentError = React.useCallback((error) => {
        setMessage(error.msg);
    });

    const renderActionButtons = (disabled) => {
        const skuData = eventData.ticket_sku;
        if (skuData === null) {
            return (
                <Button
                    disabled={disabled}
                    variant="primary"
                    onClick={(e) => handleSubmit(e)}>
                    Reserve Ticket(s)
                </Button>
            );
        }

        const data = {
            'event': eventID,
            'name': name,
            'email': email,
            'num': numTickets,
        }

        let cart = [{sku: skuData.sku, quantity: numTickets}];
        if (addOnEvent && addOnEvent != NO_ADD_ON_EVENT) {
            let addon_sku = eventData.ticket_sku.metadata.addon[addOnEvent].sku;
            cart.push({sku: addon_sku, quantity: numTickets});
        }

        return (
            <PayPalPurchaseButton
                successCallback={onPaymentSuccess}
                errorCallback={onPaymentError}
                coupon={couponAccepted ? usedCoupon : null}
                cart={cart}
                rData={data}
            />
        );
    };

    const renderPrice = () => {
        
        return (
            <Form.Group as={Row} className="subtotalRow">
                <Container fluid="md">
                    <Row>
                        <Col>Subtotal</Col>
                        <Col className="price">{formatPrice(subtotal)}</Col>
                    </Row>
                    <Row>
                        <Col>Fee</Col>
                        <Col className="price">{formatPrice(fee)}</Col>
                    </Row>
                    {discount > 0 && 
                     <Row>
                         <Col>Discount</Col>
                         <Col className="price">{'(' + formatPrice(discount) + ')'}</Col>
                     </Row>
                    }
                    <Row>
                        <Col>Total</Col>
                        <Col className="price">{formatPrice(totalPrice)}</Col>
                    </Row>
                </Container>
            </Form.Group>
        );
    };

    if (unitPrice == 0) {
        return null;
    }
    
    if (ticketLinks && ticketLinks.length > 0) {
        if (!noSKU) {
            return (
                <Card>
                    <Card.Header>Successfully Purchased Tickets </Card.Header>
                    <Card.Body>
			<h4>Thank you for your support!</h4>
			
			Your digital ticket(s) has been sent to <b>{email}</b> from cocoa@corgillogical.com.
			<br /> <br />
			Be sure to check your spam folder, just in case. If you still cannot find the email after 10 minutes,
			feel free to email cocoa@corgillogical.com for support.
			
                        <br /> <br />
                        You may also click the button below to access your tickets.
						
			<ConfirmationModal
			    ticketLinks={ticketLinks}
			    show={showConf}
			    onHide={() => setShowConf(false)}
			/>
			    
		    </Card.Body>
		    <Card.Footer>
			<Button variant="primary" onClick={() => setShowConf(true)}>
			    View Ticket(s)
			</Button>
		    </Card.Footer>
                </Card>
            );
        }
        return (
            <Card>
                <Card.Header> Successfully Reserved Tickets </Card.Header>
                <Card.Body>
		    Your digital ticket reservation has been sent to <b>{email}</b> from cocoa@corgillogical.com.
		    <br />
		    Be sure to check your spam folder, just in case. If you still cannot find the email after 10 minutes,
		    feel free to email cocoa@corgillogical.com for support.
		    
		    <br />
		    <h4 style={{color: 'red'}}>
			Remember that your tickets are
			not valid until you reached out to the event coordinator to confirm payment!
		    </h4>

		    <br />
		    You may also click the button below to access your ticket(s).

		    <ConfirmationModal
			ticketLinks={ticketLinks}
			show={showConf}
			onHide={() => setShowConf(false)}
		    />
                </Card.Body>
		<Card.Footer>
		    <Button variant="primary" onClick={() => setShowConf(true)}>
			View Ticket(s)
		    </Button>
		</Card.Footer>
            </Card>
        );
    }

    const renderHeader = () => {
        let ticketHeader = noSKU ? 'Reserve Tickets' : 'Purchase Tickets';
        if (eventData.remaining > 20) {
            return <Card.Header>{ticketHeader}</Card.Header>;
        }

        let popText = 'Not many tickets left!';
        let iconColor = 'orange';

        if (eventData.remaining < 10) {
            popText = 'Last few tickets!';
            iconColor = 'orangered';
        }

        if (!canReserve) {
            popText = 'Sold out!';
            iconColor = 'red';
        }

        return (
            <Card.Header>
                {ticketHeader}
                <div className="eventTicketInventoryIndicator">
                    <OverlayTrigger
                        placement="top-end"
                        overlay={<Tooltip>{popText}</Tooltip>}>
                        <Fire color={iconColor} size={20} />
                    </OverlayTrigger>
                </div>
            </Card.Header>
        );
    }

    if (!canReserve) {
        return (
            <Card>
                {renderHeader()}
                <Card.Body>
                    Sorry, we are sold out! Please check back later to see
                    if we release more tickets.
                </Card.Body>
            </Card>
        );
    }

    const renderNumTicketSelection = () => {
        let numRemaining = !canReserve ? 0 : Math.min(eventData.remaining, 6);
        return (
            <InputGroup size="sm" className="mb-3" controlId="formNumTickets">
                <InputGroup.Text>Number of Ticket(s)</InputGroup.Text>
                <Form.Select
                    aria-label="Number of Tickets"
                    onChange={e => {
                        setNumTickets(e.target.value);
                    }}
                >
                    {Array.from(
                        Array(numRemaining), (e, i) => {
                            let v = i + 1;
                            return <option key={'num-option-' + v} value={v}>{v}</option>;
                        },
                    )}
                </Form.Select>
            </InputGroup>
        );
    };

    const renderRelatedEvents = () => {
        if (noSKU) {
            return null;
        }

        let skuData = eventData.ticket_sku.metadata;
        if (!('addon' in skuData)) {
            return null;
        }
        
        let relatedEvents = skuData.addon;
        if (Object.keys(relatedEvents).length == 0) {
            return null;
        }

        return (
            <InputGroup size="sm" className="mb-3" controlId="formNumTickets">
                <InputGroup.Text>Add Ons</InputGroup.Text>
                <Form.Select
                    aria-label="Add ons"
                    onChange={e => {
                        setAddOnEvent(e.target.value);
                    }}
                >
                    <option key="addon-option-none" value={NO_ADD_ON_EVENT}>None</option>
                    {Object.entries(relatedEvents).map(([k, v]) =>
                        <option key={'addon-option-' + k} value={k}>{v.text}</option>
                    )}
                </Form.Select>
            </InputGroup>
        );
    };
    
    const renderCouponStuff = () => {
        const inputClass = couponAccepted === null ? '' : (
            couponAccepted ? 'border-success' : 'border-danger'
        );
        const canRemoveCoupon = couponAccepted === true;
        const handleCouponButtonClick = (e) => {
            if (canRemoveCoupon) {
                setUsedCoupon(null)
                setCouponAccepted(null);
            } else {
                setUsedCoupon(coupon)
            }
        };
        return (
            <InputGroup size="sm" className="mb-3">
                <Form.Control
                    disabled={canRemoveCoupon}
                    className={inputClass}
                    placeholder="Promo Code"
                    aria-label="Promotional Code"
                    value={coupon}
                    onChange={e => setCoupon(e.target.value)}
                />
                <Button
                    variant="outline-secondary"
                    onClick={handleCouponButtonClick}
                >
                    {canRemoveCoupon ? 'Remove' : 'Apply'}
                </Button>
            </InputGroup>
        );
    };
    
    return (
        <Card>
            {renderHeader()}
            <Card.Body>

                {!isLoaded && <Loading text="Processing reservation..." />}

                {message && <Alert variant="danger">{message}</Alert>}

        <Form noValidate validated={validated} className="purchaseTicketForm">
            <InputGroup size="sm" className="mb-3" controlId="formName">
                <InputGroup.Text>Full Name</InputGroup.Text>
                <Form.Control
                    required
                    value={name}
                    type="text"
                    placeholder="Your Name"
                    onChange={e => setName(e.target.value)}
                />
            </InputGroup>

            <InputGroup size="sm" className="mb-3" controlId="formEmail">
                <InputGroup.Text>Email address</InputGroup.Text>
                <Form.Control
                    required
                    value={email}
                    type="email"
                    placeholder="Enter email"
                    onChange={e => setEmail(e.target.value)}
                />
            </InputGroup>

            {renderNumTicketSelection()}

            {renderRelatedEvents()}

            {renderCouponStuff()}

            {noSKU && 

            <Form.Group as={Row} className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                    required
                    type="checkbox"
                    label="I understand that tickets are not valid until I reach out to the event coordinator to confirm payment, and 
                    reservation may be voided if we cannot confirm your payment."
                    onChange={e => setUnderstood(e.target.checked)} />
            </Form.Group>

            }

            {noSKU || renderPrice()}

            {renderActionButtons(!understood || !isLoaded)}
        </Form>

            </Card.Body>
        </Card>
    );
}
